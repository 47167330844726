<template>
    <div class="view-div">
        <div class="main-box">
            <div :class="['container', 'container-login']">
                <el-form v-if="!loginIn" ref="loginFormRef" :model="loginForm" :rules="loginFormRules" size="large" v-loading="loading">
                <h2 class="title">Sign in to SpotRobot</h2>
                <el-form-item prop="AccountNumber">
                    <el-input class="form__input" v-model="loginForm.AccountNumber" placeholder="请输入账号" size="large" oninput="if(value.length>11)value=value.slice(0,11)">
                </el-input>
                </el-form-item>
                <el-form-item prop="Password">
                    <el-input class="form__input" type="password" v-model="loginForm.Password" placeholder="请输入密码" size="large" show-password
                                oninput="if(value.length>18)value=value.slice(0,18)"
                                onkeyup="this.value=this.value.replace(/[^\w]/g,'')">
                    </el-input>
                </el-form-item>
                <div class="select_div">
                    <el-checkbox v-model="automaticLogon">自动登录</el-checkbox> 
                </div>
                <el-form-item class="button_div">
                    <el-button type="primary" @click="submitLogin">登录</el-button>
                    <el-button type="info" @click="resetLoginForm">重置</el-button>
                </el-form-item>
                </el-form>
            </div>
            <div :class="['introduce']">
                <div class="introduce__circle"></div>
                <div class="introduce__circle introduce__circle_top"></div>
                <div class="introduce__container">
                <div class="avatar_box_div">
                    <img src="../assets/img/lego.png" />
                </div>
                <h2>斑点机器人</h2>
                <p>
                    欢 迎 登 陆 <br/>
                    斑 点 教 务 系 统 v5.7.2
                </p>
                </div>
            </div>
        </div>
    </div>
  </template>
  
<script>
import Vue from 'vue';
import { Button,Form,FormItem,Input,Message,MessageBox,Loading,Checkbox } from 'element-ui';
Vue.use(Checkbox)
Vue.use(Button);
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Loading)
Vue.prototype.$message= Message
Vue.prototype.$confirm = MessageBox.confirm
import { GetLoginKey, PostLogin} from'@/assets/js/api/loginapi'
import { validAllNumberOrletter } from'@/assets/js/util/common'
export default {
    name: 'LoginBox',
    data() {
        var checkValidAllNumberOrletter = (rule,value,callback) => {
            if(validAllNumberOrletter(value)){
                return callback()
            }
            callback(new Error('账号只能由数字或字母组成'))
        }
        return{
            loginForm:{
                AccountNumber:'',
                Password:''
            },
            loginFormRules: {
                AccountNumber: [
                    { required: true, message: '账号不能为空', trigger: 'blur' },
                    { min: 3, max: 11, message: '账号长度在 3 到 11 个字符', trigger: 'blur' },
                    { validator:checkValidAllNumberOrletter, trigger: 'blur'}
                ],
                Password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                    { min: 3, max: 18, message: '密码长度在 3 到 18 个字符', trigger: 'blur' }
                ]
            },
            automaticLogon:false,
            loading:false,
            loginIn:false,
        }
    },
    mounted(){
        window.addEventListener('keydown',this.keyDown);
    },
    destroyed(){
        window.removeEventListener('keydown',this.keyDown, false);
    },
    created(){
        this.loginIn = false
        this.getAutomaticLogon()
        if(this.automaticLogon){
            this.autoLogin()
        }
    },
    methods:{
        onDragStart(){
            event.preventDefault();
        },
        keyDown(e){
            //如果是回车则执行登录方法
            if(e.keyCode == 13){
                this.submitLogin();
            }
        },
        resetLoginForm(){
            if(this.$refs.loginFormRef){
                this.$refs.loginFormRef.resetFields();
            }
        },
        getAutomaticLogon(){
            if(this.$cookies.isKey("SRAUTOLOGIN")){
                var tag = this.$cookies.get("SRAUTOLOGIN") 
                if(tag == "true"){
                    this.automaticLogon = true
                }
                else{
                    this.automaticLogon = false
                }
            }   
            else{
                this.automaticLogon = false
            }
        },
        async autoLogin(){
            this.loginForm.AccountNumber = this.$cookies.get("SRACCOUNT")
            this.loginForm.Password = this.$cookies.get("SRPD")
            this.loading = true
            var ret = await PostLogin(this.loginForm)
            if(ret == null){
                this.$refs.loginFormRef.resetFields()
                this.loginForm.AccountNumber = ''
                this.loginForm.Password = ''
                this.loading = false
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1){
                this.$cookies.set("SRAUTOLOGIN",this.automaticLogon,"7d")
                this.$cookies.set("SRJWTTOKEN",ret.token,"7d")
                this.$cookies.set("SRACCOUNT",this.loginForm.AccountNumber,"7d")
                if(this.automaticLogon){
                    this.$cookies.set("SRPD",this.loginForm.Password ,"7d")
                }
                this.$router.replace('/Manager');
                this.$message.success('欢迎登陆');
                this.loginIn=true
                this.loading = false
            }
            else{
                this.$refs.loginFormRef.resetFields()
                this.loginForm.AccountNumber = ''
                this.loginForm.Password = ''
                this.$message.error(ret.message);
                this.loading = false
            }
        },
        async submitLogin(){
            this.$refs.loginFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return this.$message.error('请填写正确的账号或者密码格式');
            }
            this.loading = true
            var ret = await GetLoginKey(this.loginForm.AccountNumber);
            if(ret == null){
                this.loading = false
                this.$refs.loginFormRef.resetFields()
                this.loginForm.AccountNumber = ''
                this.loginForm.Password = ''
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1){
                var key = ret.data
                let sha512 = require("js-sha512").sha512
                this.loginForm.Password = sha512(this.loginForm.Password  + key)
                var retdata = await PostLogin(this.loginForm)
                if(retdata == null){
                    this.loading = false
                    this.$refs.loginFormRef.resetFields()
                    this.loginForm.AccountNumber = ''
                    this.loginForm.Password = ''
                    return this.$message.error('无法请求到服务器，请联系管理员');
                }
                if(retdata.code == 1){
                    this.$cookies.set("SRAUTOLOGIN",this.automaticLogon,"7d")
                    this.$cookies.set("SRJWTTOKEN",retdata.token,"7d")
                    this.$cookies.set("SRACCOUNT",this.loginForm.AccountNumber,"7d")
                    if(this.automaticLogon){
                        this.$cookies.set("SRPD",this.loginForm.Password ,"7d")
                    }
                    this.$router.replace('/Manager');
                    this.$message.success('欢迎登陆');
                     this.loginIn=true
                }
                else{
                    this.$refs.loginFormRef.resetFields()
                    this.loginForm.AccountNumber = ''
                    this.loginForm.Password = ''
                    this.$message.error(retdata.message);
                }
            }
            else{
                this.$refs.loginFormRef.resetFields()
                this.loginForm.AccountNumber = ''
                this.loginForm.Password = ''
                this.$message.error(ret.message);
            } 
            //延时增加效果
            // var start = new Date().getTime();
            // while(new Date().getTime() < start + 1000);
            this.loading = false
        }
    }
}
</script>

<style  lang="less" scoped>
    .view-div{
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: var(--color-login-background)
    }
    .main-box {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 800px;
        min-width: 800px;
        min-height: 500px;
        height: 500px;
        padding: 25px;
        background-color: #ecf0f3;
        // box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
        border-radius: 12px;
        overflow: hidden;
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            width: 650px;
            height: 85%;
            padding: 25px;
            background-color: #ecf0f3;
            transition: all 1.25s;
            form {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: 55%;
                color: #a0a5a8;
                .title {
                    font-size: 34px;
                    font-weight: 700;
                    line-height: 3;
                    color: #181818;
                }
                .text {
                    margin-top: 30px;
                    margin-bottom: 12px;
                }
                .form__input {
                    width: 350px;
                    height: 40px;
                    margin: 4px 0;
                    // padding-left: 25px;
                    font-size: 13px;
                    letter-spacing: 0.15px;
                    border: none;
                    outline: none;
                    // font-family: 'Montserrat', sans-serif;
                    background-color: #ecf0f3;
                    transition: 0.25s ease;
                    border-radius: 8px;
                    &::placeholder {
                        color: #a0a5a8;
                    }
                }
            }
        }

        .container-login {
            left: calc(100% - 600px);
            z-index: 0;
        }
        .is-txl {
            left: 0;
            transition: 1.25s;
            transform-origin: right;
        }
        .is-z200 {
            z-index: 200;
            transition: 1.25s;
        }
        .introduce {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 250px;
            padding: 50px;
            z-index: 200;
            transition: 1.25s;
            background-color: #ecf0f3;
            overflow: hidden;
            box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
            color: #a0a5a8;
            .avatar_box_div{
                height: 130px;
                width: 130px;
                border:1px solid #eee;
                border-radius: 50%;
                padding:10px;
                box-shadow:0 0 10px #ddd;
                background-color:#fff;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: #eee;
                    image-rendering: -moz-crisp-edges; /* Firefox */
                    image-rendering: -o-crisp-edges; /* Opera */
                    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                    image-rendering: crisp-edges;    
                    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
                    user-select: none;
                }
            }
            .introduce__circle {
                position: absolute;
                width: 500px;
                height: 500px;
                border-radius: 50%;
                background-color: #ecf0f3;
                box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
                bottom: -55%;
                left: -60%;
                transition: 1.25s;
            }
            .introduce__circle_top {
                top: -30%;
                left: 60%;
                width: 300px;
                height: 300px;
            }
            .introduce__container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: absolute;
                width: 400px;
                padding: 50px 55px;
                transition: 1.25s;
                h2 {
                    font-size: 34px;
                    font-weight: 700;
                    line-height: 3;
                    color: #181818;
                }
                p {
                    font-size: 14px;
                    letter-spacing: 0.25px;
                    text-align: center;
                    line-height: 1.6;
                }
            }
        }

        .select_div{
            margin: -5px 260px 0 0;
            display: flex;
        }
        .button_div{
            display: flex;
            margin: 0 0 0 230px;
            justify-content: flex-end;
        }
    }
</style>
  
  