import axios from 'axios';

//获取密钥
export async function GetLoginKey(logininfo){
    var res = await axios.get('LoginKey', {params: {accountnumber : logininfo}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}

//登录
export async function PostLogin(logininfo){
    var res = await axios.post('Login', logininfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
}